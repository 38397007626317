<template>

	<div class="partial partial--privacy privacy">
		<div class="privacy__header">
			<span>Already have an account? &nbsp;</span>
			<router-link :to="{ name: 'login' }" class="button button--unelevated button--secondary button--medium">Login</router-link>
		</div>

		<div class="partial__heading">
			<h1 class="partial__title">Privacy Policy</h1>
		</div>

		<div class="partial__content">
			<p>Regardless of changes in technology and information, SouthState has always been, and will continue to be, committed to the principles of customer privacy. We understand that when you open an account with us, apply for a loan, or deal with us or one of our affiliated companies in any way, we ask you to provide us with private financial and personal information.</p>

			<p>We honor the trust you place in us by maintaining the confidentiality and accuracy of that information, and we use it in manners consistent with the confidence you have placed in us. We will uphold both the letter and the spirit of federal and state laws as they relate to this important issue.</p>

			<a href="https://media.ssbcdn.com/kenticomediaprod/ssbweb/media/pdf/south-state-privacy-notice-rev-12-19.pdf" target="_blank">Privacy Notice [PDF]</a>

			<p>We reserve the right to change this notice at any time by posting a new privacy policy notice. We encourage you to carefully review the notice and contact us with any questions.</p>

			<p>Rev. 12/19</p>

			<p><br></p>

			<table class="privacy__table">
				<tbody>
					<tr>
						<th width="108"><strong>FACTS</strong></th>
						<th colspan="3" width="612"><strong>WHAT DOES SouthState </strong><strong>DO WITH YOUR PERSONAL INFORMATION?</strong></th>
					</tr>
					<tr>
						<td width="108"><strong>Why?</strong></td>
						<td colspan="3" width="612">Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand what we do.</td>
					</tr>
					<tr>
						<td width="108"><strong>What?</strong></td>
						<td colspan="3" width="612">The types of personal information we collect and share depend on the product or service you have with us. This information can include:
						<ul>
							<li>Social Security number and income;</li>
							<li>Account balances and transaction/payment history;</li>
							<li>Employment information and credit history.</li>
						</ul>
						<p>When you are no longer our customer, we continue to share your information as described in this notice.</p>
						</td>
					</tr>
					<tr>
						<td width="108"><strong>How?</strong></td>
						<td colspan="3" width="612">All financial companies need to share customers’ personal information to run their everyday business. In the section below, we list the reasons financial companies can share their customers’ personal information; the reasons SouthState chooses to share; and whether you can limit this sharing.</td>
					</tr>
					<tr>
						<th colspan="2" width="348"><strong>Reasons we can share your personal information</strong></th>
						<th width="186"><strong>Does SouthState&nbsp; share?</strong></th>
						<th width="186"><strong>Can you limit this sharing?</strong></th>
					</tr>
					<tr>
						<td colspan="2" width="348"><strong>For our everyday business purposes –</strong>
							<p>such as: to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus</p>
						</td>
						<td width="186">Yes</td>
						<td width="186">No</td>
					</tr>
					<tr>
						<td colspan="2" width="348"><strong>For our marketing purposes –</strong>
					<p>to offer our products and services to you</p>
					</td>
						<td width="186">Yes</td>
						<td width="186">No</td>
					</tr>
					<tr>
						<td colspan="2" width="348"><strong>For joint marketing with other financial companies</strong></td>
						<td width="186">Yes</td>
						<td width="186">No</td>
					</tr>
					<tr>
						<td colspan="2" width="348"><strong>For our affiliates’ everyday business purposes –</strong>
					<p>information about your transactions and experiences</p>
					</td>
						<td width="186">Yes</td>
						<td width="186">No</td>
					</tr>
					<tr>
						<td colspan="2" width="348"><strong>For our affiliates’ everyday business purposes –</strong>
					<p>information about your creditworthiness</p>
					</td>
						<td width="186">No</td>
						<td width="186">We Don’t Share</td>
					</tr>
					<tr>
						<td colspan="2" width="348"><strong>For our affiliates to market to you</strong></td>
						<td width="186">No</td>
						<td width="186">We Don’t Share</td>
					</tr>
					<tr>
						<td colspan="2" width="348"><strong>For nonaffiliates to market to you</strong></td>
						<td width="186">No</td>
						<td width="186">We Don’t Share</td>
					</tr>
				</tbody>
			</table>

			<table>
				<tbody>
					<tr>
						<td width="108"><strong>Questions?</strong></td>
						<td colspan="3" width="612">Call toll-free at 1-800-277-2175.</td>
					</tr>
				</tbody>
			</table>

			<table class="privacy__table" style="width: 100%;">
				<tbody>
					<tr>
						<th width="216">Who We Are</th>
						<th width="504">&nbsp;</th>
					</tr>
					<tr>
						<td width="216"><strong>Who is providing this notice?</strong></td>
						<td width="504">The SouthState Corporation group of companies including a bank (SouthState Bank) and a registered investment advisor (SouthState Advisory, Inc.). SouthState Advisory also operates as Minis &amp; Company and SouthState Retirement Plan Services.</td>
					</tr>
				</tbody>
				<tbody>
					<tr>
						<th width="216"><strong>What We Do</strong></th>
						<th width="504">&nbsp;</th>
					</tr>
					<tr>
						<td width="216"><strong>How does SouthState protect my personal information?</strong></td>
						<td width="504">To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings.</td>
					</tr>
					<tr>
						<td width="216"><strong>How does SouthState collect my personal information?</strong></td>
						<td width="504">We collect your personal information, for example, when you
							<ul>
							<li>Open an account, deposit money, or perform transactions;</li>
							<li>Pay your bills or apply for a loan;</li>
							<li>Use your credit or debit card.</li>
							</ul>
							<p>We also collect your personal information from others, such as credit bureaus, affiliates, or other companies.</p>
						</td>
					</tr>
					<tr>
						<td width="216"><strong>Why can’t I limit all sharing?</strong></td>
						<td width="504">Federal law gives you the right to limit only
							<ul>
							<li>Sharing for affiliates’ everyday business purposes – information about your creditworthiness;</li>
							<li>Affiliates from using your information to market to you;</li>
							<li>Sharing for nonaffiliates to market to you.</li>
							</ul>
							<p>State laws and individual companies may give you additional rights to limit sharing.</p>
						</td>
					</tr>
				</tbody>
				<tbody>
					<tr>
						<th width="216"><strong>Definitions</strong></th>
						<th width="504">&nbsp;</th>
					</tr>
					<tr>
						<td width="216"><strong>Affiliates</strong></td>
						<td width="504">Companies related by common ownership or control. They can be financial and nonfinancial companies.
						<p><em>The SouthState Corporation group of companies are affiliates of each other and are financial services companies that include a bank (SouthState Bank) and a registered investment advisor (SouthState Advisory, Inc.).</em></p>
						</td>
					</tr>
					<tr>
						<td width="216"><strong>Nonaffiliates</strong></td>
						<td width="504">Companies not related by common ownership or control. They can be financial and nonfinancial companies.
							<ul>
							<li><em>SouthState does not share with nonaffiliates so they can market to you.</em></li>
							</ul>
						</td>
					</tr>
					<tr>
						<td width="216"><strong>Joint Marketing</strong></td>
						<td width="504">A formal agreement between nonaffiliated financial companies that together market financial products or services to you.
							<ul>
							<li><em>Our joint marketing partner includes a credit card company</em></li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

</template>

<script>
export default {
	name: 'partialPrivacy',
	metaInfo: () => ({
		title: 'Privacy Policy',
	}),
}
</script>

<style scoped lang="scss">
.privacy{

	&__header {
		@include modules.gutter('margin-bottom');

		text-align: right;

		span {
			@include modules.gutter('padding-right');
			flex: 1;
		}

		@include modules.media-query('phone') {
			align-items: center;
		}
	}

	&__table {
		width: 100%;
		//max-width: 80%;

		th {
			color: #FFF;
			background: modules.color_('primary');
			padding: 8px 8px;
			//border-bottom: 1px solid modules.color_('border');
		}

		td {
			padding: 8px 8px;
			border-bottom: 1px solid modules.color_('border');
		}
	}
}

</style>
